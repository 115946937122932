<template>
  <div>
    <div class="bread">
      <div>
        首页 > 课程 > 课程详情
      </div>
    </div>
    <div class="play">
      <div class="content">
        <div class="left">
          <div class="leftUpper">
            <div class="leftUpperImg">
              <img :src="detailWhole.face_img"
                   alt="">
            </div>
            <div class="leftUpperWord">
              <div class="leftUpperWordTit">
                {{detailWhole.title}}
              </div>
              <div class="leftUpperWordLabel">
                <div class="leftUpperWordLabelNum">
                  <div class="leftUpperWordLabelNumOne">
                    <div style="margin-right: 29px">课程评分<span style="margin-left: 8px">{{detailWhole.score}}</span></div>
                    <div v-if="detailWhole.type != 1">{{detailWhole.study_count}}人在学</div>
                  </div>
                  <div class="leftUpperWordLabelNumTwo"
                       v-if="detailWhole.plan_time.length != 0"
                       @click="joinPlanClick">
                    <!-- <div v-if="detailWhole.type == 2" style=" color: #ff1717; margin-right: 44px; ">{{detailWhole.rate}}%</div> -->
                    <div v-if="detailWhole.type == 2"> 计划完成时间
                      <span style=" margin-left: 8px; color: #333;"
                            v-if="detailWhole.plan_time.length != 0&&detailWhole.plan_status == -1">{{detailWhole.plan_time.year}}-{{detailWhole.plan_time.month}}-{{detailWhole.plan_time.day}} {{detailWhole.plan_time.hour}}:{{detailWhole.plan_time.min}}</span>
                      <span style="margin-left: 8px; color: #333;"
                            v-else>{{detailWhole.plan_status == 1?'已完成':'超时'}}</span>
                    </div>
                  </div>
                  <div v-if="detailWhole.type == 1"
                       style="text-align: initial;font-size: 14px;color: #9f9f9f;">直播开始时间
                    <span style="margin-left: 8px;">{{detailWhole.direct_time}}</span>
                  </div>
                </div>
                <div class="leftUpperWordLabelClick">
                  <div class="leftUpperWordLabelClickBut">
                    <div class="leftUpperWordLabelClickButOne"
                         v-if="detailWhole.plan_time.length == 0"
                         @click="joinPlanClick">
                      加入学习计划
                    </div>
                    <!-- 直播 -->
                    <div class="leftUpperWordLabelClickButTwo"
                         v-if="detailWhole.type == 1"
                         @click="goStart(id,curriculumList[0].url,curriculumList[0].id)">
                      开始学习
                    </div>
                    <!-- 有课程目录 -->
                    <div class="leftUpperWordLabelClickButTwo"
                         v-if="detailWhole.type == 2&&curriculumList.length != 1"
                         @click="goDemand(id,indexVid)">
                      开始学习
                    </div>
                    <!-- 没课程目录 -->
                    <div class="leftUpperWordLabelClickButTwo"
                         v-if="detailWhole.type == 2&&curriculumList.length == 1"
                         @click="goDemand(id,curriculumList[0].url)">
                      开始学习
                    </div>
                  </div>
                  <div class="leftUpperWordLabelClickOperation">
                    <div class="leftUpperWordLabelClickOperationImg"
                         style="padding-right: 31px"
                         v-if="detailWhole.type != 1"
                         @click="evaluateClick">
                      <img src="@/assets/img/play/197.png"
                           alt="" />
                      <span>评价</span>
                    </div>
                    <div class="leftUpperWordLabelClickOperationImg"
                         @click="shareClick">
                      <img src="@/assets/img/play/183.png"
                           alt="" />
                      <span>分享</span>
                    </div>
                    <div class="leftUpperWordLabelClickOperationImg"
                         style="padding-left: 31px"
                         v-if="detailWhole.type != 1">
                      <!-- pc不支持下载 -->
                      <!-- <img src="@/assets/img/play/348.png" alt="" />
                                            <span>下载</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- tab 切换 -->
          <div class="leftLower">
            <div style="">
              <div class="leftLowerTab">
                <div class="tabStyle"
                     :class="{tabStyleBorder:tabIndex==ie.id}"
                     v-for="(ie,ia) in tabContent"
                     :key="ia"
                     @click="tabClick(ie)">
                  <div>{{ie.name}}</div>
                </div>
              </div>
              <!-- 课程介绍 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==1">
                <curriculum :wordHtml="content"></curriculum>
              </div>
              <!-- 讲师介绍 -->
              <div v-if="tabIndex==2">
                <lecturer :wordhtml='lecturerContent'></lecturer>
              </div>
              <!-- 课程目录 -->
              <div v-if="tabIndex==3">
                <catalog-curriculum :whole="curriculumList"
                                    @on-change="changeVideo"></catalog-curriculum>
              </div>
              <!-- 评论 -->
              <div v-if="tabIndex==4">
                <details-comment :wholeComment="comment"
                                 :id='id'
                                 @comment-change='commentReturn'></details-comment>
              </div>
            </div>
            <!-- 评论input框 -->
            <div class="commentInp"
                 v-if="tabIndex==4">
              <p>发表评论</p>
              <div class="commentInpPro">
                <div class="commentInpProBorder">
                  <img src="@/assets/img/play/editicon.png"
                       alt="">
                  <input type="text"
                         v-model="commentContent"
                         placeholder="在此写下您的看法">
                </div>
                <div class="commentInpProBut"
                     @click="commentClick">发表评论</div>
              </div>
            </div>
            <!-- 广告位 轮播图 -->
            <!--  -->
            <div style="height:91px;width:90%;padding:30px 43px;"
                 v-if="imgPro.image_pc != null"
                 @click="goother({path: '/play/richText',query:{id:id}})">
              <img style="height:100%;width:100%;"
                   :src="imgPro.image_pc">
            </div>
          </div>
        </div>
        <!-- 右侧 推荐内容 -->
        <div class="right">
          <div style="background: #fff;padding: 27px 27px 10px;">
            <div class="rightHead">
              <img src="@/assets/img/play/247.png"
                   alt="" />
              <span>热门课程</span>
            </div>
            <!-- 推荐课程 -->
            <div class="rightDetail"
                 v-for="(it,is) in hotList.res"
                 :key="is"
                 :class="{curr:is%2 == 0}"
                 @click="switchHot(it.id)">
              <!-- 有图换这个 -->
              <img :src="it.face_img"
                   alt=""
                   class="rightDetailimg">
              <div class="rightDetailTitle">
                {{it.title}}
              </div>
              <div class="rightDetailTime">
                {{it.duration}}
              </div>
              <div style="display: flex;align-items: center;">
                <div class="rightDetailName"
                     v-for="(ic,iz) in it.lecturer_info"
                     :key="iz">
                  <img :src="ic.portrait"
                       alt="">
                  <div>{{ellipsText(ic.real_name)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="计划完成时间"
               :visible.sync="joinPlan"
               width="30%">
      <el-date-picker style="width:330px;"
                      v-model="plan_time"
                      type="datetime"
                      :picker-options="pickerOptions"
                      value-format="yyyy,MM,dd,HH,mm"
                      :placeholder="tipsTime">
      </el-date-picker>
      <div class="joiningTimeClass"
           @click="joiningTime">确定完成时间</div>
    </el-dialog>
    <el-dialog title="课程评分"
               :visible.sync="dialogVisible"
               width="20%">
      <div class="newsConent">
        <div class="newsConentExb"><span>课程先进性</span>
          <el-rate v-model="value1"
                   :disabled="scoreBoolean"></el-rate>
        </div>
        <div class="newsConentExb"><span>课程实用性</span>
          <el-rate v-model="value2"
                   :disabled="scoreBoolean"></el-rate>
        </div>
        <div class="newsConentExb"><span>讲师表现力</span>
          <el-rate v-model="value3"
                   :disabled="scoreBoolean"></el-rate>
        </div>
      </div>
      <div :class="detailWhole.course_score_info == 0?'newsBut0':'newsBut1'"
           @click="evaluateClick1">{{detailWhole.course_score_info == 0?'评价':'您已提交评价'}}</div>
    </el-dialog>
    <el-dialog :visible.sync="share"
               width="35%">
      <div style="display: flex;justify-content: center;align-items: center;">
        <div class="shareLeft">
          <img :src="poster"
               alt="">
        </div>
        <div class="shareRight">
          <div class="shareRightWord">
            <p>请下载邀请卡分享给您的好友</p>
            <p>每成功邀请1位好友完成手机号注册</p>
            <p>将获得20积分奖励</p>
          </div>
          <div class="shareRightBut"
               @click="download">
            下载邀请卡
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import curriculum from '@/components/curriculum'
import lecturer from '@/components/lecturer'
import catalogCurriculum from '@/components/catalogCurriculum'
import detailsComment from '@/components/detailsComment'
import { course_info, hot_course, add_comment, add_study_plan, time_set, add_course_score, course_dealImage, scerweima } from '@/utils/Api/play'
import { istoinfo } from '@/utils/Api/commonList'
import { Message, MessageBox } from 'element-ui'
import { isMobile } from '@/utils/auth.js'

export default {
  name: "play",
  components: { curriculum, lecturer, catalogCurriculum, detailsComment, },
  data () {
    return {
      id: null,//课程id
      tabType: 1,//直播还是点播
      tabIndex: 1,//tab切换下标
      tabContent: [],//tab切换展示
      hotList: [],//热门课程
      // 直播
      tab: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }],
      // 点播
      tabPlay: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }, {
        id: 3,
        name: '课程目录',
        page: 1
      }, {
        id: 4,
        name: '评论',
        page: 1
      }],
      // 不想写了 只有课程目录只有一节的时候
      tabPlayPro: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }, {
        id: 4,
        name: '评论',
        page: 1
      }],
      content: "",//富文本内容
      lecturerContent: "",//讲师富文本内容
      imgPro: [],//轮播图
      curriculumList: [],//课程目录
      comment: [],//评论
      commentContent: null,//评论内容
      detailWhole: { plan_time: {} },//全部订单详情
      commentWhether: '',//评论返回信息
      indexVid: null,//课程详情页点击课程目录返回的vid
      catalogIndex: null,//目录下标
      remain_time: 0,//页面停留时间
      see_long: 0,//视频观看时间
      video: null,//视频id
      looked: null,//上次观看进度条
      joinPlan: false,//加入学习计划弹窗
      plan_time: '',//学习计划时间
      pickerOptions: { disabledDate: (time) => { return this.dealDisabledDate(time) } },
      dialogVisible: false,//评价
      value1: 5,//评价1
      value2: 5,//评价2
      value3: 5,//评价3
      scoreBoolean: false,//评价控制能否点击
      numberValue: null,//评价内容返回
      tipsTime: '选择日期时间',//选择提示
      share: false,//分享
      poster: null,//海报接口返回内容
      qrCode: null,//二维码
      la: null,
      titleName: '课程详情',
      isMobi: false
    }
  },
  created () {

    this.isMobi = isMobile()

    if (this.isMobi) {
      window.open(`http://nmep.com.cn/#/pages/players/video_brief?course_id=${this.$route.query.id}`)
      // window.location.href = `http://nmep.com.cn/#/pages/players/video_brief?course_id=${this.$route.query.id}`
      return
    }


    // console.log('现在路由多少',this.$route)
    console.log('用户信息', this.$store.getters.personal, this.$store.getters.token)
    this.id = this.$route.query.id
    this.details()
    this.hotCurriculum()
    // console.log('现在id是多少',this.id)
  },
  mounted () {

  },
  methods: {
    goStart (id, vid, ids) {
      const Router = this.$router
      istoinfo({ course_id: id }).then(res => {
        if (res.status == 11037) {
          MessageBox.confirm('请登录后继续观看课程，是否去登录？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去登陆',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/login', query: { redirect: encodeURI(Router.app._route.fullPath) } })
          }).catch(() => {
            // next(from.fullPath)
          })
          return
        }
        if (res.status == 11038) {
          MessageBox.confirm('请完善个人信息后继续观看课程，是否去完善？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去完善',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personal/myinfo', query: { redirect: encodeURI(Router.app._route.fullPath) } })
          }).catch(() => {
            // next(from.fullPath)
          })
          return
        }
        this.goother({ path: '/play/livePlay', query: { id: id, vid: vid, ids: ids } })
      })
    },
    // 课程详情接口
    async details () {
      this.detailWhole = await course_info({ course_id: this.id })
      this.content = this.detailWhole.introduce
      this.lecturerContent = this.detailWhole.lecturer_introduce
      this.imgPro = this.detailWhole.advert_info
      this.curriculumList = this.detailWhole.catalogue
      this.comment = this.detailWhole.comment

      this.detailWhole.type == 1 ? this.tabContent = this.tab : this.curriculumList.length == 1 ? this.tabContent = this.tabPlayPro : this.tabContent = this.tabPlay
      if (this.detailWhole.course_score_info != 0) {
        this.value1 = this.detailWhole.course_score_info.advanced
        this.value2 = this.detailWhole.course_score_info.utility
        this.value3 = this.detailWhole.course_score_info.expressive
        this.detailWhole.course_score_info != 0 ? this.scoreBoolean = true : scoreBoolean = false
      }


      if (this.$store.getters.personal && this.$store.getters.personal.id) {
        this.scerweimaPro()
      }

    },
    // 热门课程
    async hotCurriculum () {
      this.hotList = await hot_course({ type: 1, page: 1, limit: 2 })
      // console.log('热门课程',this.hotList)
    },
    async scerweimaPro () {
      let url = `/pages/players/video_brief?course_id=${this.id}`
      // if(this.$store.getters.personal.capacity == 3){
      // 	url = `/pages/my/join_mechanism?name=${this.$store.getters.personal.hospital.full_name}`
      // }
      // console.log('获取code',this.$store.getters.personal.code)
      let b = {
        url: url,
        thoken: this.$store.getters.token,
        invite_code: this.$store.getters.personal.code,
        organ_id: this.$store.getters.personal.id,
      }
      console.log('ssj', b)
      this.qrCode = await scerweima(b)
      this.courseDealImage()
    },
    async courseDealImage () {
      let a = {
        course_id: this.id,//	是	string	课程id
        code_url: this.qrCode,//	是	string	二维码地址
        code_x: 210,//	是	string	二维码x坐标 大概 800
        code_y: 395,//	是	string	二维码y坐标 大概1600
        code_w: 80,//	是	string	二维码宽 大概100
        code_h: 80,//	是	string	二维码高 大概100
        bill_y: 120,//	是	string	海报y坐标 大概30
        bill_w: 300,//	是	string	海报宽度 大概1200
        bill_h: 10,//	是	string	海报高度 大概300
        head_x: 18,//	是	string	头像x坐标 大概200
        head_y: 313,//	是	string	头像y坐标 大概1400
        head_w: 50,//	是	string	头像宽 大概300
        head_h: 50,//	是	string	头像高 大概300
        name_x: 85,//	是	string	姓名x坐标 大概200
        name_y: 330,//	是	string	姓名y坐标 大概1400
        name_size: 12,//	是	string	姓名文字大小 大概40
        title_x_diff: -25,//	是	string	课程名偏移量 大概150
        title_y: 70,//	是	string	课程名y坐标 大概1000
        title_size: 16,//	是	string	课程名像素 大概40
        title_w: 200,//	是	string	课程名宽度 大概700
      }
      this.poster = await course_dealImage(a)
      console.log(this.poster)
      this.la = this.poster.split('qrcode/')[1]
      console.log('海报图', this.poster)
    },
    // tab点击事件
    tabClick (e) {
      console.log('点击', e)
      this.tabIndex = e.id
    },
    // 点击发表评论
    commentClick () {
      this.addComment()
    },
    // 评论接口
    async addComment () {
      if (this.commentContent != null) {
        this.commentWhether = await add_comment({ course_id: this.id, content: this.commentContent })
        if (this.commentWhether.length == 0) {
          this.commentContent = null
          this.details()
          this.$message.success(`评论成功`)
        }
      } else {
        this.$message.error(`请先填写内容`)
      }
    },
    // 截取文字 点点点
    ellipsText (text, num = 4) {
      if (text) {
        if (text.length > num) {
          return text.substring(0, num) + '...';
        } else {
          return text;
        }
      } else {
        return text
      }
    },
    // 评论个人信息返回
    commentReturn (e) {
      // console.log('评论个人信息返回',e)
      this.details()
    },
    // 点击课程目录 返回的 vid
    changeVideo (e) {
      // console.log('课程详情页',e)
      this.indexVid = e.vidPro
      this.catalogIndex = e.ind
      this.remain_time = e.remain_time
      this.see_long = e.see_long
      this.video = e.video
      this.looked = e.looked
      // if(this.curriculumList.length == 1){
      //     this.goDemand(this.id,this.indexVid)
      // }
      this.goDemand(this.id, this.indexVid)
    },
    // 跳转页面传值
    goDemand (id, vid) {
      console.log('asjfaaklhfkh', this.indexVid, this.tabIndex, id, vid)
      // if(this.curriculumList.length != 1&&(this.indexVid == null||this.tabIndex!=3)){
      //     this.$message.error(`请在课程目录先选择课程`);
      //     return;
      // }

      if (this.curriculumList.length == 1) {
        this.catalogIndex = 0
        this.remain_time = this.curriculumList[0].remain_time
        this.looked = this.curriculumList[0].looked
        this.see_long = this.curriculumList[0].see_long
        this.video = this.curriculumList[0].id
      }
      if (this.indexVid == null) {
        this.catalogIndex = 0
        this.remain_time = this.curriculumList[0].remain_time
        this.looked = this.curriculumList[0].looked
        this.indexVid = this.curriculumList[0].url
        this.see_long = this.curriculumList[0].see_long
        this.video = this.curriculumList[0].id
      }
      // 改传参方式需要在路由页面修改路由
      // 第三个 需要引用 vuex 在 src/store/index.js
      // this.$store.dispatch('play/set_id', id)
      // this.$store.dispatch('play/set_vid', vid)
      // 第三种方法
      // this.goother({path:'/play/noDemand',query:{id: id,vid: vid,index:this.catalogIndex,remain_time:this.remain_time,see_long:this.see_long,videoId:this.video,looked:this.looked}})


      const Router = this.$router
      istoinfo({ course_id: id }).then(res => {
        if (res.status == 11037) {
          MessageBox.confirm('请登录后继续观看课程，是否去登录？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去登陆',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/login', query: { redirect: encodeURI(Router.app._route.fullPath) } })
          }).catch(() => {
            // next(from.fullPath)
          })
          return
        }
        if (res.status == 11038) {
          MessageBox.confirm('请完善个人信息后继续观看课程，是否去完善？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去完善',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personal/myinfo', query: { redirect: encodeURI(Router.app._route.fullPath) } })
          }).catch(() => {
            // next(from.fullPath)
          })
          return
        }
        this.$router.push({
          // 第二个
          name: 'noDemand',
          params: {
            id: id,
            vid: this.indexVid,
            index: this.catalogIndex,
            remain_time: this.remain_time,
            see_long: this.see_long,
            videoId: this.video,
            looked: this.looked
          }
          // 第一个
          // path: '/play/noDemand',
          // query:{
          //     id: id,
          //     vid: vid
          // }
        })
      })


      // this.$router.push({
      //     // 第二个
      //     name: 'noDemand',
      //     params:{
      //         id: id,
      //         vid: this.indexVid,
      //         index:this.catalogIndex,
      //         remain_time:this.remain_time,
      //         see_long:this.see_long,
      //         videoId:this.video,
      //         looked:this.looked
      //     }
      //     // 第一个
      //     // path: '/play/noDemand',
      //     // query:{
      //     //     id: id,
      //     //     vid: vid
      //     // }
      // })
    },
    // 热门课程
    // hotSee(e){
    //     console.log('热门课程',e)
    //     this.$router.push({
    //         path: '/play',
    //         query:{
    //             id: e.id
    //         }
    //     })
    // },
    // 课程详情页 点击热门课程
    switchHot (e) {
      this.id = e
      this.details()
      this.hotCurriculum()
    },
    // 加入学习计划
    joinPlanClick () {
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      if (this.detailWhole.type == 2) {
        this.joinPlan = true
      }
      if (this.detailWhole.plan_time.length != 0) {
        this.tipsTime = this.detailWhole.plan_time.year + '-' + this.detailWhole.plan_time.month + '-' + this.detailWhole.plan_time.day + ' ' + this.detailWhole.plan_time.hour + ':' + this.detailWhole.plan_time.min
        console.log('计划时间', this.plan_time)
        return;
      }
      if (this.detailWhole.type == 1) {
        this.addStudyPlan()
        this.details()
      }
    },
    // 加入学习计划
    async addStudyPlan () {
      this.addStudyPlanData = await add_study_plan({ course_id: this.id })
      // console.log(this.timeSetData)
      if (this.detailWhole.type == 1) {
        this.details()
        this.$message.success(`加入学习计划成功`)
      } else {
        this.timeSet()
      }
    },
    // 设置计划完成时间
    async timeSet () {
      this.timeSetData = await time_set({ course_id: this.id, plan_time: this.plan_time })
      this.$message.success(`加入学习计划成功`)
      this.details()
    },
    // 学习计划时间换算
    dealDisabledDate (time) {
      const d = new Date()
      d.setFullYear(d.getFullYear() + 2)
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() > d.getTime()
    },
    // 加入时间按钮
    joiningTime () {
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      if (this.plan_time.length == 0) {
        this.$message.error(`请选择时间`)
        return;
      }
      this.addStudyPlan();
      this.joinPlan = false
      // this.details()
      // console.log('加入时间',this.plan_time)
    },
    // 点击评价按钮
    evaluateClick1 () {
      console.log('提交评价')
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      console.log('走过用户检测')
      if (this.detailWhole.course_score_info == 0) {
        console.log('走入提交前')
        this.addCourseScore();
      }
    },
    async addCourseScore () {
      console.log('走到提交xhr')
      this.numberValue = await add_course_score({ course_id: this.id, advanced: this.value1, utility: this.value2, expressive: this.value3 })
      if (this.numberValue.length == 0) {
        this.$message.success(`评分成功`)
        setTimeout(() => {
          this.dialogVisible = false
          this.details()
        }, 100)
      }
    },
    // 下载
    download () {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL(this.poster)
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'photo'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = this.poster
      console.log('图片', image)
    },
    // 分享按钮
    shareClick () {
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      this.share = true
    },
    // 评价点击事件
    evaluateClick () {
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      this.dialogVisible = true
    }
  },
};
</script>

<style scoped>
.bread {
  width: 100%;
  padding: 13px 0;
  font-size: 12px;
  color: #6c6c6c;
  background: #fafafa;
}
.bread > div {
  width: 1200px;
  margin: 0 auto;
  text-align: initial;
}
.play {
  width: 100%;
  background: #fafafa;
}
.content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 953px;
  margin-right: 19px;
}
.leftUpper {
  /* width: 100%; */
  /* height: 279px; */
  background: #fff;
  padding: 35px 31px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.leftUpperImg {
  /* flex: 1; */
  /* width: 388px;
    height: 218px; */
  /* background: blue; */
  margin-right: 25px;
}
.leftUpperImg img {
  width: 355px;
  height: 218px;
}
.leftUpperWord {
  width: 58%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftUpperWordTit {
  display: flex;
  width: 100%;
  min-height: 49px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}
.leftUpperWordLabel {
  flex: 1;
  /* height: 68%; */
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftUpperWordLabelNumOne {
  display: flex;
  font-size: 14px;
  color: #9f9f9f;
  margin-bottom: 11px;
}
.leftUpperWordLabelNumTwo {
  display: flex;
  font-size: 14px;
  color: #9f9f9f;
  align-items: center;
}
.leftUpperWordLabelClick {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftUpperWordLabelClickBut {
  display: flex;
}
.leftUpperWordLabelClickButOne {
  width: 131px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #47d7e3;
  border-radius: 4px;
  color: #fff;
  margin-right: 8px;
  cursor: pointer;
}
.leftUpperWordLabelClickButTwo {
  width: 99px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff7c2b;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.leftUpperWordLabelClickOperation {
  display: flex;
}
.leftUpperWordLabelClickOperationImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.leftUpperWordLabelClickOperation img {
  width: 24px;
  height: 22px;
}
.leftUpperWordLabelClickOperationImg {
  color: #777777;
  font-size: 12px;
}
.leftUpperWordLabelClickOperationImg span {
  margin-top: 4px;
}

/* 课程介绍 */
.leftLower {
  /* height: 1640px; */
  margin: 16px 0 30px;
  background: #fff;
}
.leftLowerTab {
  display: flex;
  text-align: initial;
  padding: 19px 31px;
}

.tabStyle {
  padding: 9px 0;
  font-size: 16px;
  color: #333;
  margin-right: 39px;
  cursor: pointer;
}

.tabStyleBorder {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  border-bottom: 4px solid #51d5dd;
  margin-right: 39px;
  cursor: pointer;
}
/* 富文本样式 */
.htmlWordStyle {
  /* widows: 706px; */
  /* height: 1381px; */
  margin: 0 auto;
  /* text-align: initial ; */
  /* overflow-y: auto; */
}
/* 右边 */
.right {
  width: 336px;
  /* background: #fff; */
  /* padding: 0 27px; */
}
.rightHead {
  color: #333333;
  font-size: 24px;
  display: flex;
}
.rightHead img {
  width: 23px;
  height: 31px;
  margin-right: 17px;
}
.rightDetail {
  padding-bottom: 20px;
  cursor: pointer;
}
.rightDetailimg {
  width: 100%;
  height: 140px;
  margin: 27px 0 20px;
  background: #47d7e3;
}
.rightDetailTitle {
  text-align: initial;
}
.rightDetailTime {
  text-align: initial;
  margin: 13px 0;
}
.rightDetailName {
  text-align: initial;
  display: flex;
  margin-right: 5px;
}
.rightDetailName img {
  width: 26px;
  height: 26px;
  /* background: #47d7e3; */
  border-radius: 100px;
  overflow: hidden;
  margin-right: 9px;
}
.curr {
  border-bottom: 1px solid #dddddd;
}
/* 发表评论 */
.commentInp {
  margin-top: 19px;
  padding: 17px 31px;
}
.commentInp p {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  text-align: initial;
  margin-bottom: 5px;
}
.commentInpPro {
  display: flex;
  height: 52px;
  padding: 0 62px;
}
.commentInpProBorder {
  display: flex;
  align-items: center;
  border: 1px solid #bdb7b7;
  width: 83%;
  padding: 0 13px;
}
.commentInpProBorder img {
  width: 18px;
  height: 20px;
}
.commentInpProBorder input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  margin-left: 18px;
  color: #909090;
}
.commentInpProBut {
  width: 86px;
  height: 100%;
  background: #47d7e3;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.joiningTimeClass {
  width: 330px;
  height: 37px;
  background: #47d7e3;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 20px auto 0;
  border-radius: 5px;
}
/* 评价 */
.newsConent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsConentExb {
  display: flex;
  font-size: 15px;
  color: #666666;
  margin-bottom: 10px;
}
.newsConentExb span {
  margin-right: 37px;
}
.newsBut0 {
  width: 192px;
  height: 32px;
  margin: 0 auto;
  background: #47d7e3;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.newsBut1 {
  width: 192px;
  height: 32px;
  margin: 0 auto;
  background: #dedede;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.shareStyle {
  display: flex;
  justify-content: center;
}
.shareLeft {
  width: 201px;
  height: 357px;
  /* background: red; */
}
.shareLeft > img {
  width: 100%;
  height: 100%;
}
.shareRight {
  width: 402px;
  height: 229px;
  background: #f0f5fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shareRightBut {
  width: 225px;
  height: 53px;
  background: #47d7e3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}
</style>
